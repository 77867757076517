import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | sobre nós"
        description="Gostamos de pensar sobre nós mesmos como um negócio pequeno, preciso e cuidadoso."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>Sobre nós</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                Gostamos de pensar sobre nós mesmos como um negócio pequeno,
                preciso e cuidadoso. Como um bistrot sempre estamos em busca de
                melhorar nossa eficiência.
                <br />
                <br />
                Nós conduzimos nosso negócio com ética, e estamos preocupados
                com a nossa privacidade, com a de nossos clientes, e com a dos
                usuários de nossos produtos.
                <br />
                <br />
                Colocamos o máximo esforço e cuidado em todos os detalhes de
                cada projeto em que trabalhamos, e propiciamos aos nossos
                clientes o mesmo tratamento que gostaríamos de receber.
                <br />
                <br />
                Estamos constantemente expandindo nossas habilidades e buscando
                novas ferramentas para dar a melhor resposta para cada
                necessidade.
                <br />
                <br />
                <br />E obviamente nos divertimos com isso.
                <br />
                <br />
                Somos um Bistrot.
                <br />
                <br />
                &nbsp;
                <br />
                <br />
                Seja bem vindo!
              </span>
              <div className="h-16" />
              <div className="text-center">
                <Link
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                  to="/pt/lets-work-together/"
                >
                  Vamos trabalhar juntos?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="pt" />
    </>
  )
}

export default IndexEN
